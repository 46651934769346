.about-us {
  padding: 60px 20px; /* Add padding for spacing */
  background-color: var(--contact-section-bg-color);
  text-align: center;
  color: var(--primary-text-color);
  border: 2px solid var(--secondary-bg-color);
  border-radius: 10px;
}

.about-us h2 {
  margin-bottom: 20px;
  font-size: 2em;
}

.about-us p {
  font-size: 1.2em;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}

.custom-bullet-list {
  list-style-type: none;
  padding: 0;
  text-align: left; /* Align text to the left */
  max-width: 1000px; /* Set a max-width for better readability */
  margin: auto; /* Center the list within the section */
}

.custom-bullet-list li {
  font-size: 1.2em;
  color: var(--secondary-text-color);
  margin-bottom: 10px;
  padding-left: 40px; /* Adjust padding for the bullet icon */
  background: url('../Images/ButtonIcon.png') no-repeat left center;
  background-size: 40px 40px; /* Adjust size as needed */
  line-height: 1.6; /* Adjust line height for better readability */
}

.custom-bullet-list li strong {
  color: var(--primary-text-color);
}
