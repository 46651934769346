.hero {
  background: url('../Images/LabelBySunchet.png') no-repeat center center;
  background-size: contain; /* Ensures the whole image is visible */
  background-color: var(--contact-section-bg-color); /* Fallback color */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: calc(var(--header-height) + 20px); /* Adjust padding to account for header */
  padding-bottom: 60px; /* Adjust as needed for content */
  min-height: 50vh; /* Ensure a minimum height */
  border: 2px solid var(--secondary-bg-color);
  border-radius: 10px;
}

.hero-content {
  background-color: transparent;
  padding: 20px;
  border-radius: 10px;
}
