.contact-section {
    padding: 60px 20px; /* Add padding for spacing */
    background-color: var(--contact-section-bg-color);
    border: 2px solid var(--secondary-bg-color);
    border-radius: 10px;
  }
  
  .contact-container {
    display: flex;
    flex-direction: row;
    background-color: var(--primary-bg-color); /* Use the global color */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 800px;
    width: 100%;
    margin: 0 auto; /* Center the container */
  }
  
  .contact-details, .contact-form {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .contact-details {
    background-color: var(--primary-bg-color); /* Use the global color */
    color: var(--primary-text-color);
    text-align: left;
    padding: 40px;
  }
  
  .contact-form {
    text-align: left;
    padding: 40px;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #1a1818;
    border-radius: 5px;
  }
  
  .contact-form button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #333;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact-form button:hover {
    background-color: #555;
  }
  
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
      padding: 20px;
    }
  
    .contact-details, .contact-form {
      padding: 20px;
    }
  }
  