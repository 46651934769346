.calendly-section {
  display: flex;
  flex-direction: column; /* Change to column layout for better mobile support */
  align-items: center;
  padding: 40px 20px; /* Add padding for better spacing */
  border: 2px solid var(--secondary-bg-color);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: var(--contact-section-bg-color); /* Use the global color */
  color: var(--primary-text-color);
}

.calendly-description {
  max-width: 100%; /* Ensure it takes full width on mobile */
  margin-bottom: 20px; /* Add spacing between elements */
  text-align: center; /* Center align text */
}

.calendly-description h2 {
  margin-bottom: 20px;
  font-size: 1.8em;
}

.calendly-description p {
  font-size: 1.2em;
  color: var(--secondary-text-color);
}

.calendly-container {
  width: 100%; /* Ensure it takes full width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendly-inline-widget {
  width: 100%; /* Ensure widget takes full width */
  height: 630px;
}

/* Media Queries for responsiveness */
@media (min-width: 768px) {
  .calendly-section {
    flex-direction: row; /* Change to row layout for larger screens */
    padding: 40px;
  }

  .calendly-description {
    max-width: 40%; /* Limit width for larger screens */
    margin-right: 20px; /* Add spacing between elements */
    text-align: left; /* Left align text for larger screens */
  }

  .calendly-container {
    flex-grow: 1;
  }
}

@media (max-width: 480px) {
  .calendly-description h2 {
    font-size: 1.5em; /* Reduce font size for smaller screens */
  }

  .calendly-description p {
    font-size: 1em; /* Reduce font size for smaller screens */
  }

  .calendly-container {
    height: auto; /* Allow the container to adjust height based on content */
  }

  .calendly-inline-widget {
    height: 100%; /* Ensure widget adjusts height based on content */
  }
}
