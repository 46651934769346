@import './Variables.css';

header {
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bolder;
  
}

.logo {
  font-size: var(--header-font-size);
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 20px;
  margin-left: auto; /* Aligns nav-links to the right */
}

.nav-links a {
  color: var(--primary-text-color);
  text-decoration: none;
  padding: 10px 15px;
  font-size: var(--navbar-font-size);
  white-space: nowrap; /* Prevent text wrapping */
}

.nav-links a:hover {
  background-color: var(--secondary-bg-color);
  border-radius: 5px;
}

.hamburger {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    gap: 0;
    position: absolute;
    top: var(--header-height);
    left: 0;
    width: 100%;
    background-color: var(--primary-bg-color);
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links a {
    padding: 10px 20px;
    border-top: 1px solid var(--secondary-bg-color);
  }
}

@media (max-width: 480px) {
  .nav-links a {
    font-size: 1rem;
  }
}
