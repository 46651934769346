footer {
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
    text-align: center;
    padding: 20px;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .social-links a {
    display: inline-block;
    margin: 10px;
    text-decoration: none;
    color: var(--primary-text-color);
    background-color: var(--primary-bg-color);
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .social-links a:hover {
    background-color: var(--secondary-bg-color);
  }
  
  .social-links i {
    margin-right: 8px;
  }
  