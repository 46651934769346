@import './components/Variables.css';

/* Global styles */
:root {
  --base-font-size: 16px;
  --header-font-size: 1.5rem;
  --navbar-font-size: 1.2rem;
  --contact-form-font-size: 1rem;
  --header-height: 70px; /* Adjust this value based on your header's actual height */
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-size: var(--base-font-size);
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
}

section {
  padding: 60px 20px; /* Add padding for spacing */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Add general styling for headings, paragraphs, and links */
h1, h2, h3, h4, h5, h6 {
  color: var(--primary-text-color);
  margin: 0 0 10px;
}

p {
  margin: 0 0 15px;
  color: var(--secondary-text-color);
}

a {
  color: var(--primary-text-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Global container to center content */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .container {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  body {
    font-size: 12px;
  }

  .container {
    padding: 0 5px;
  }
}
